html,
body {
  width: 100%;
  height: auto;
  background-color: #f7f5ed;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h3 {
  font-family: 'Recoleta';
  font-weight: 500;
  text-align: center;
}

pre {
  padding: 15px;
  font-size: 12px;
  text-align: left;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* PROGRESS BAR */
.progressbar {
  max-width: 400px;
  height: 10px;
  background-color: #cef2f1;
  margin-bottom: 50px;
}

.progressbar div {
  width: 33.3%;
  height: 100%;
  background-color: #74a6ad;
}
label {
  font-family: 'CircularXX';
  font-size: 15px;
}
